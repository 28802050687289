import React from 'react';
import Tier from './Tier';
import './App.css';
import girmp4 from './photos/girmp4.mp4';
import wink from './photos/wink.gif';
import youtube from './photos/youtube.png';
import twitch from './photos/twitch.png';
import twitter from './photos/twitter.png';
import kofi from './photos/kofi.png';
import arcade from './photos/arcade.gif';
import blackhole from './photos/blackhole.png';
import coffe from './photos/coffe.webp';
import discordwalk from './photos/discordwalk.gif';
import saber from './photos/saber.webp';
import walk from './photos/walk.gif';
import futon from './photos/futon.png';

function App() {
  const openTwitterDM = (message) => {
    const encodedMessage = encodeURIComponent(message);
    window.open(`https://twitter.com/messages/compose?recipient_id=1216467425098489856&text=${encodedMessage}`, '_blank');
  };

  const kofiGallery = () => {
    window.open('https://ko-fi.com/giraffimation/gallery', '_blank');
  }

  const tiers = [
    {
      title: 'Price Range: $35-$100',
      services: [
        'FBX to VRM',
        'VRchat to VRM',
        'VRM to VRChat',
        'Texture ReColor',
        'POI Shaders for VRM',
        'Basic Kit-bashing',
        'VRChat Upload Setup',
        'Simple Avatar/World Props',
        'Adding a Blendshape on an existing model',
      ],
      message: "I'm interested in your services for the tier 'Price Range: $35-$100'. Can we discuss further details?"
    },
    {
      title: 'Price Range: $100-$250',
      services: [
        'ATLAS map re-texturing',
        'Humanoid ARKit',
        'Furry ARKit',
        'Advanced KitBashing',
        'Simple Model Clothing',
        'Avatar/World Rendering Per Image',
      ],
      message: "I'd like to inquire about your services for the tier 'Price Range: $100-$250'. Can you provide more information?"
    },
    {
      title: 'Price Range: $250+',
      services: [
        'Advanced Model Clothing',
        'Advanced Avatar/World Props',
        'Stream Graphics',
        'Avatar Modeling Modifications',
      ],
      message: "Interested in discussing your services for the tier 'Price Range: $250+'. Can we connect?"
    },
    {
      title: 'Priced per Project',
      services: [
        'Animations (Maximum of 5 seconds)',
        'VRChat World Commission',
        'From Scratch Avatars',
        'Live 2D Rigging',
      ],
      message: "I have a project that I'd like to discuss, particularly regarding 'Priced per Project'. Can we talk?"
    },
  ];
  const tier1 = tiers.slice(0, 1);
  const tier2 = tiers.slice(1, 2);
  const tier3 = tiers.slice(2, 3);
  const tier4 = tiers.slice(3, 4);
  
  const handleTierButtonClick = (message) => {
    openTwitterDM(message);
  };

  return (
    <div className="App">
      <div className="landing">
        <video autoPlay loop muted className="background-video">
          <source src={girmp4} type="video/mp4" />
          Your browser does not support the video tag
        </video>
        <div className="header">
          <h1>Giraffimation Studios</h1>
          <div className="social-media">
            <p>Follow me on:</p>
            <ul>
              <li><a href="https://twitter.com/giraffimation"><img src={twitter} alt="Twitter" /></a></li>
              <li><a href="https://ko-fi.com/giraffimation"><img src={kofi} alt="Ko-fi" /></a></li>
              <li><a href="https://www.youtube.com/channel/UCrSw5n8k7VwuUy8s_bQejkg"><img src={youtube} alt="YouTube" /></a></li>
              <li><a href="https://www.twitch.tv/giraffimation"><img src={twitch} alt="Twitch" /></a></li>
            </ul>
          </div>
        </div>
      </div>

      
        <div className="about-me">
          <div className="text-container">
            <div className='aboutBox'>
              <h2>About Me</h2>
              <p>
                Hello! My name is Giraffe. I am a Technical Artist, with more than a decade of experience. I am proficient in GIMP, Clip Studio Paint, Blender, Unity, Unreal Engine, and Substance Painter. My specialization is in creating high-quality assets for VTubers, streamers, and virtual content creators. With a focus on creating immersive characters and environments, I've contributed to multiple virtual world projects within VRChat, enriching the experiences of the content creators and their audiences. Explore my portfolio on Ko-fi to see some of my creations, from character models, to clothing, and detailed environments. Ready to collaborate and make something amazing together?
                <div className="gallery-container">
                  <button className="contact-button" onClick={kofiGallery}>Click to see my full portfolio!</button>
                  <div className="gallery">
                    <div className="image-container">
                      <img src={arcade} alt="Arcade" />
                    </div>
                    <div className="image-container">
                      <img src={blackhole} alt="Blackhole" />
                    </div>
                    <div className="image-container">
                      <img src={coffe} alt="Coffe" />
                    </div>
                    <div className="image-container">
                      <img src={discordwalk} alt="Discordwalk" />
                    </div>
                    <div className="image-container">
                      <img src={saber} alt="Saber" />
                    </div>
                    <div className="image-container">
                      <img src={walk} alt="Walk" />
                    </div>
                    <div className="image-container">
                      <img src={futon} alt="Futon" />
                    </div>
                  </div>
                </div>
              </p>
            </div>
          </div>
        </div>

      <div className="winkcontainer">
        <img src={wink} alt="wink" />
      </div>

      <div className="commissions">
        <h2>How do my commissions work?</h2>
       

        <div className="tierContainer">
        
  {tier1.map((tier, index) => (
    <div key={index} className='tier'>
      <a href={`https://twitter.com/messages/compose?recipient_id=1216467425098489856&text=${encodeURIComponent(tier.message)}`} target="_blank" rel="noopener noreferrer">
        <Tier 
          title={tier.title} 
          services={tier.services} 
          openTwitterDM={handleTierButtonClick} 
          message={tier.message} 
        />
      </a>
    </div>
  ))}

{tier2.map((tier, index) => (
    <div key={index} className='tier'>
        <a href={`https://twitter.com/messages/compose?recipient_id=1216467425098489856&text=${encodeURIComponent(tier.message)}`} target="_blank" rel="noopener noreferrer">
            <Tier 
              title={tier.title} 
              services={tier.services} 
              openTwitterDM={handleTierButtonClick} 
              message={tier.message} 
            />
        </a>
    </div>
))}
{tier3.map((tier, index) => (
    <div key={index} className='tier'>
        <a href={`https://twitter.com/messages/compose?recipient_id=1216467425098489856&text=${encodeURIComponent(tier.message)}`} target="_blank" rel="noopener noreferrer">
            <Tier 
              title={tier.title} 
              services={tier.services} 
              openTwitterDM={handleTierButtonClick} 
              message={tier.message} 
            />
        </a>
    </div>
))}
{tier4.map((tier, index) => (
    <div key={index} className='tier'>
        <a href={`https://twitter.com/messages/compose?recipient_id=1216467425098489856&text=${encodeURIComponent(tier.message)}`} target="_blank" rel="noopener noreferrer">
            <Tier 
              title={tier.title} 
              services={tier.services} 
              openTwitterDM={handleTierButtonClick} 
              message={tier.message} 
            />
        </a>
    </div>
))}

         
          
        </div>

        <div className='tosContainer'>
          <h3>How to Commission</h3>
          <ol>
            <li><strong>Contact Information:</strong> Reach out to me via the button below.</li>
            <li><strong>Commission Details:</strong> Provide specific details about your commission request, including desired tier, any references or ideas you have, your preferred deadline, if an NDA is required, and if you need commercial rights.</li>
            <li><strong>Discussion: </strong>We'll discuss your requirements further to ensure clarity and alignment on the project scope.</li>
            <li><strong>Agreement:</strong> Once we've agreed upon the terms, I'll provide you with a quote and any additional information.</li>
            <li><strong>Payment:</strong> Payment processing is dependent on the tiers (see the Terms of Service). I accept payments directly through KoFi which uses Stripe.</li>
            <li><strong>Work Progress: </strong>I'll keep you updated on the progress of your commission and seek feedback along the way.</li>
            <li><strong>Delivery: </strong>Upon completion, I'll deliver the final work to you as agreed. Typically through Google Drive, if the files are too big to send via Discord.</li>
          </ol>

          <h3>Terms of Service (TOS)</h3>
          <ol>
            <li><strong>Payment for Basic Tier:</strong> All payments are to be made on completion of work.</li>
            <li><strong>Payment for Simple, Intermediate, and Advanced Tiers:</strong> All payments are to be 50% upfront and 50% after work has completed.</li>
            <li><strong>Communication:</strong> Clear and timely communication is essential for the success of the project.</li>
            <li><strong>Revisions: </strong>Depending on the complexity of the project, a certain number of revisions may be included. Additional revisions may incur extra charges.</li>
            <li><strong>Rights:</strong> I retain the rights to showcase commissioned work in my portfolio or for promotional purposes unless otherwise agreed upon.</li>
            <li><strong>Deadlines:</strong> I will strive to meet agreed-upon deadlines. Delays may occur due to unforeseen circumstances, but I will communicate any changes promptly.</li>
            <li><strong>Scope Changes:</strong> Any changes to the project scope may result in adjustments to the timeline and pricing, which will be discussed and agreed upon before proceeding.</li>
            <li><strong>Cancellation:</strong> Either party has the right to cancel before the project is started, but once it has been delivered, no refunds will be issued.</li>
          </ol>
        </div>
        <button className="contact-button" onClick={() => openTwitterDM("Hello! I had some questions about your commissions?")}>Contact Me</button>
      </div>
    </div>
  );
}

export default App;
