import React from 'react';

const Tier = ({ title, services, openTwitterDM, message }) => {
  return (
    <div >
      <h3>{title}</h3>
      <ul>
        {services.map((service, index) => (
          <li key={index}>{service}</li>
        ))}
      </ul>
    </div>
  );
}

export default Tier;